import React from 'react';
import PropTypes from 'prop-types';
import styles from './ArticleItem.module.scss';
import { Link } from 'gatsby';
import cs from 'classnames';
import Paragraph from '../shared/texts/Paragraph/Paragraph';
import Button from '../shared/buttons/Button/Button';
import ArticleTitle from '../shared/texts/ArticleTitle/ArticleTitle';
import Image from 'gatsby-image';
import createSlug from 'src/components/shared/createSlug/createSlug';

const ArticleItem = ({ reverse, img, title, shortDescription }) => (
  <div className={cs(styles.articleItem, { [styles.reverse]: !reverse })}>
    <div className={styles.description}>
      <ArticleTitle className={cs(styles.articleTitle, 'wow', 'animate__fadeInUp')} data-wow-duration=".6s">{title}</ArticleTitle>
      <div className={cs(styles.descriptionWrap, 'wow', 'animate__fadeInUp')} data-wow-duration=".6s">
        <Paragraph>{shortDescription}</Paragraph>
      </div>
      <Button className={cs(styles.button, 'wow', 'animate__fadeInUp')} data-wow-duration=".6s" to={`/czytam/${createSlug(title)}`}>
        czytaj
      </Button>
    </div>
    <div className={cs(styles.dogWrap)}>
      <div className={cs(styles.overlay, 'wow', 'animBoth', {'animate__slideOutRight': !reverse}, {'animate__slideOutLeft': reverse})} data-wow-duration=".6s"></div>
      <Image fluid={img} alt="Dog" className={cs(styles.dog)}/>
    </div>
    <Link className={styles.mobileArrow} to={`/czytam/${createSlug(title)}`}/>
  </div>
);

ArticleItem.propTypes = {
  reverse: PropTypes.bool,
  img: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

ArticleItem.defaultProps = {
  revers: false,
};

export default ArticleItem;
