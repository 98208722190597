import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames'
import styles from './Paragraph.module.scss'

const Paragraph = ({children, className, ...props}) => (
  <p {...props} className={cs(styles.paragraph, className)}>
    {children}
  </p>
);

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Paragraph.defaultProps = {
  className: ''
};

export default Paragraph
