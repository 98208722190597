import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cs from 'classnames';
import styles from './Button.module.sass'

const Button = ({children, className, to, ...props}) => (
  <Link {...props} className={cs(styles.button, className)} to={to}>
    {children}
  </Link>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string
};

Button.defaultProps = {
  className: ''
};

export default Button